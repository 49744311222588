/*
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-26 22:38:20
 * @LastEditTime: 2021-10-14 13:38:37
 */

const HOST_JE = ''

// load
export const POST_LOAD = `${HOST_JE}/je/dd/ddItem/load`
// 获取用户登录信息
export const POST_USER_INFO = `${HOST_JE}/jepaas/login/getUserInfoById`
// 修改头像到用户信息里
export const POST_EDIT_PHOTO = `${HOST_JE}/jepaas/login/editPhoto`
// 发送验证码
export const POST_RANDOM = `${HOST_JE}/jepaas/login/sendRandom`
// 支付
export const POST_PLACE_PAY = `${HOST_JE}/jepaas/order/placePay`
// 注册
export const POST_REGISTER = `${HOST_JE}/jepaas/login/signIn`
// 登录
export const POST_LOGIN = `${HOST_JE}/jepaas/login/login`
// APP打包申请的版本
export const POST_getAppProDic = `${HOST_JE}/jepaas/jeapp/shop/getAppProDic`
// APP打包校验服务器地址是否有效
export const POST_testConnection = `${HOST_JE}/jepaas/jeapp/shop/testConnection`
// APP打包修改PC授权码
export const POST_changePcCredentials = `${HOST_JE}/jepaas/jeapp/shop/changePcCredentials`
// APP打包删除
export const POST_getAppdoRemove = `${HOST_JE}/jepaas/jeapp/shop/doRemove`
// APP打包导入
export const POST_getAppShopInfo = `${HOST_JE}/jepaas/jeapp/shop/getAppShopInfo`
// APP打包修改表单
export const POST_APP_doUpdate = `${HOST_JE}/jepaas/jeapp/shop/doUpdateShop`
// APP打包提交表单
export const POST_APP_doSave = `${HOST_JE}/jepaas/jeapp/shop/doSaveShop`
// 绑定蒜客户接口
export const POST_BIND_CUSTOMER = `${HOST_JE}/jepaas/login/bindCustomer`
// 解绑蒜客户接口
export const POST_unBindCustomer = `${HOST_JE}/jepaas/login/unBindCustomer`
// 工单退回验收失败
export const POST_rollback = `${HOST_JE}/jepaas/worklist/rollback`
// 工单的修改接口
export const POST_doUpdate = `${HOST_JE}/jepaas/worklist/doUpdate`
// 工单的项目名称字典
export const POST_project = `${HOST_JE}/jepaas/worklist/project`
// 工单送交验收成功
export const POST_complete = `${HOST_JE}/jepaas/worklist/complete`
// 工单提交的列表删除事件
export const POST_doRemove = `${HOST_JE}/jepaas/worklist/doRemove`
// 工单提交的列表数据获取
export const POST_load_work = `${HOST_JE}/jepaas/worklist/load`
// 工单提交接口
export const POST_sponsorProcess = `${HOST_JE}/jepaas/worklist/sponsorProcess`
// 工单VIP的列表接口
export const POST_viptime = `${HOST_JE}/jepaas/worklist/viptime`
// 工单提交校验接口
export const POST_checkVipService = `${HOST_JE}/jepaas/worklist/checkVipService`
// 工单提交的保存接口
export const POST_doSave = `${HOST_JE}/jepaas/worklist/doSave`
// 工单提交的字典接口
export const POST_Dictionary = `${HOST_JE}/jepaas/worklist/dictionary`
// 工单计划接口
export const POST_plan = `${HOST_JE}/jepaas/worklist/plan`
// 点击我的工单校验这个用户有没有挂接客户，没有的话提示联系客服挂接客户
export const POST_checkUserInfo = `${HOST_JE}/jepaas/worklist/checkUserInfo`
// 找回密码
export const POST_GET_PASSWORD = `${HOST_JE}/jepaas/login/findPassword`
// 修改密码
export const POST_EDIT_PASSWORD = `${HOST_JE}/jepaas/login/editPassword`
// 修改手机号
export const POST_EDIT_PHONE = `${HOST_JE}/jepaas/login/editPhone`
// 完善用户信息并提交
export const POST_SUB_USER_INFO = `${HOST_JE}/jepaas/login/editUserInfo`
// 获取商业证书的策略信息
export const POST_Product_CONFIG = `${HOST_JE}/jepaas/product/loadTacticsByProductId`
// 获取我的订单
export const POST_ORDER_LIST = `${HOST_JE}/jepaas/order/loadOrderInfo`
// 获取用户下的商业证书
export const POST_ORDER_SYZS = `${HOST_JE}/jepaas/product/loadCertificateByUserId`
// 获取商品类型字典
export const POST_ORDER_TYPE = `${HOST_JE}/jepaas/product/loadDictionaryInfo`
// 已购订单的订单取消
export const POST_ORDER_REMOVE = `${HOST_JE}/jepaas/order/cancelOrder`
// 已开发票
export const POST_INVOICE_INFO = `${HOST_JE}/jepaas/invoice/getInvoiceInfo`
// 添加发票
export const POST_INVOICE_ADDINVOICE = `${HOST_JE}/jepaas/invoice/addInvoice`
// 发票详情
export const POST_INVOICE_DETAIL = `${HOST_JE}/jepaas/invoice/getInvoiceDetail`

// 获取合同信息
export const POST_CONTRACT_INFO = `${HOST_JE}/jepaas/contract/getContractInfo`
// 添加合同信息
export const POST_CONTRACT_ADDCONTRACT = `${HOST_JE}/jepaas/contract/addContract`
// 获取合同详情
export const POST_GET_CONTRACT_DETAIL = `${HOST_JE}/jepaas/contract/getContractDetail`
// 获取合同模板 /jepaas/contract/loadContractModel
export const POST_LOAD_CONTRACT_MODEL = `${HOST_JE}/jepaas/contract/loadContractModel`

// 获取白条权限或者额度
export const POST_GET_WHITE_BAR_INFO = `${HOST_JE}/jepaas/whiteBar/getWhiteBarInfo`

// 白条消费基础信息
export const POST_GET_WHITE_BAR_DETAIL = `${HOST_JE}/jepaas/whiteBar/getWhiteBarDetail`
//  获取白条结算状态
export const POST_LOAD_WHITE_BAR_ORDER_INFO = `${HOST_JE}/jepaas/whiteBar/loadWhiteBarOrderInfo`
// 白条结算
export const POST_FAST_PAYMENTS = `${HOST_JE}/jepaas/whiteBar/fastPayments`

// 获取历史版本列表
export const POST_HISTORY_VERSION = `${HOST_JE}/jepaas/version/historyVersion`
// 获取历史升级包
export const POST_HISTORY_UPGRADE_PACKAGE = `${HOST_JE}/jepaas/version/historyUpgradePackage`
// 获取版本详情
export const POST_VERSION_DETAILS = `${HOST_JE}/jepaas/version/versionDetails`
// 下载升级包
export const POST_DOWNLOAD_UPGRADE_PACKAGE = `${HOST_JE}/jepaas/version/downloadUpgradePackage`
// 动态获取banner图信息
export const POST_GET_BANNER = `${HOST_JE}/jepaas/product/loadbannerInfo`
// 获取证书使用详情页面 白名单、黑名单、关联证书等数据
export const POST_LOAD_MACHINE_INFO = `${HOST_JE}/jepaas/order/loadMachineInfo`
// 机器使用详情白名单列表  备注编辑和解除绑定操作
export const POST_EDIT_MACHINE_INFO = `${HOST_JE}/jepaas/order/editMachineInfo`
// 机器使用详情黑名单列表  删除操作
export const POST_REMOVE_BLACKLIST = `${HOST_JE}/jepaas/order/removeBlacklist`
// 已购订单的修改版权
export const POST_EDIT_COPYRIGHT = `${HOST_JE}/jepaas/copyrightAudit/editCopyright`
// 校验版权审核中是否有  审核中的数据
export const POST_CHECKAUDITSTATUS = `${HOST_JE}/jepaas/copyrightAudit/checkAuditStatus`
// 校验版权审核中是否有  审核中的数据
export const POST_REJECTINFO = `${HOST_JE}/jepaas/order/loadRejectInfo`
// 搜索接口
export const POST_SEARCH = `${HOST_JE}/je/search/query`
// 搜索首页数据
export const POST_SEARCHHOME = `${HOST_JE}/je/search_web/searchHome/getHotTitle`
// 问答提交
export const POST_SAVEQUS = `${HOST_JE}/je/search_web/searchHome/saveQus`
// 获取字典信息
export const POST_SELECTDIC = `${HOST_JE}/je/search_web/adminSearch/selectDic`
// 点击次数增加
export const POST_ADDONCE = `${HOST_JE}/je/search_web/searchHome/addOnce`

// 获取策略的数据
export const POST_CONSULT_URL = `${HOST_JE}/jepaas/mallInformation/initialization`;
