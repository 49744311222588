/*
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-26 13:54:59
 * @LastEditTime: 2020-04-15 18:40:41
 */
// 表单提交的正则判断
const rules = {
  /**
     * @name: 中文姓名正则判断
     * @Descripttion:
     * @param {type}
     * @returns:
     */
  validateName(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入您的姓名!'));
    }
    if (!(/^[\u4E00-\u9FA5]{2,10}$/.test(value))) {
      return callback(new Error('姓名格式有误!'));
    }
    return callback();
  },
  /**
     * 手机号正则判断
     * @param rule 判断条件
     * @param value 判断的值
     * @param callback  回调判断
     */
  validatePhone(rule, value, callback) {
    if (!value) {
      return callback(new Error('手机号输入不能为空!'));
    }
    if (!(/^1[3456789]\d{9}$/.test(value))) {
      return callback(new Error('手机号格式有误!'));
    }
    return callback();
  },
  /**
     * 登录的密码正则判断
     * @param rule
     * @param value
     * @param callback
     */
  rulesLoginPassword(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入您的密码!'));
    }
    return callback();
  },
  /**
     * 验证码
     * @param rule
     * @param value
     * @param callback
     */
  rulesPhoneCode(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入您的验证码!'));
    }
    return callback();
  },
  /**
     * 邮箱判断
     * @param rule
     * @param value
     * @param callback
     */
  rulesRegEmail(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入邮箱!'));
    }
    if (!(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value))) {
      return callback(new Error('邮箱格式不正确!'));
    }
    return callback();
  },
};

export default rules;
