//  When I wrote this code, only God and I knew what it was. Now, only God knows!
<!--
 * @Description:
 * @Author: yjp
 * @Date: 2020-03-30
 * @LastEditTime: 2020-05-07 14:48:54
 * @FilePath: /src/pages/index/views/my/contract/contractForm.vue
 -->
<template>
  <el-form
    ref="ruleForm"
    :model="ruleForm"
    :rules="rules"
    label-width="180px"
    class="contract-ruleForm"
    size="small"
  >
    <div class="download_but">
      <!-- <a
        href="/website/contractTemplate/JEPAAScontractTemplate.docx"
        target="_blank"
        download
      >合同模板下载 <i class="el-icon-bottom" /></a> -->
      <span
        class="hover-download"
        @click.stop.prevent="getContractModel"
      >
        合同模板下载
      </span>
    </div>

    <el-form-item
      label="合同名称:"
      prop="contractName"
    >
      <el-input v-model="ruleForm.contractName" />
    </el-form-item>
    <el-form-item
      label="合同金额:"
      prop="money"
    >
      <el-input
        v-model="contractAmount"
        :readonly="true"
      />
    </el-form-item>
    <el-form-item
      label="甲方名称:"
      prop="firstParty"
    >
      <el-input v-model="ruleForm.firstParty" />
    </el-form-item>
    <el-form-item
      label="负责人:"
      prop="fzr"
    >
      <el-input v-model="ruleForm.fzr" />
    </el-form-item>
    <el-form-item
      label="签订日期:"
      prop="contractDate"
    >
      <el-date-picker
        v-model="ruleForm.contractDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      />
    </el-form-item>
    <el-divider />
    <el-form-item
      label="收件人:"
      prop="recipients"
    >
      <el-input
        v-model="ruleForm.recipients"
        class="sjr"
      />
    </el-form-item>
    <el-form-item
      label="手机号:"
      prop="phone"
    >
      <el-input
        v-model="ruleForm.phone"
        class="sjr"
      />
    </el-form-item>
    <el-form-item
      label="地址:"
      prop="address"
    >
      <el-input v-model="ruleForm.address" />
    </el-form-item>
    <el-form-item class="submitBtn">
      <el-button
        size="medium"
        type="danger"
        @click="doSubmit"
      >
        提交
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { POST_CONTRACT_ADDCONTRACT } from '@/pages/index/actions/api';
import { loadContractModel } from '@/pages/index/actions/actions';
import rules from '@/util/rulesUtil';

export default {
  data() {
    return {
      contractAmount: '',
      ids: '',
      ruleForm: {
        ids: '', // 订单ids
        address: '', // 地址
        contractDate: '', // 签订日期
        contractName: '', // 合同名称
        firstParty: '', // 甲方
        fzr: '', // 负责人
        phone: '', // 收件人手机号
        recipients: '', // 收件人
      },
      rules: {
        contractName: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        contractDate: [{ required: true, message: '请选择签订日期', trigger: 'blur' }],
        firstParty: [{ required: true, message: '请输入甲方名称', trigger: 'blur' }],
        fzr: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        phone: [{ required: true, validator: rules.validatePhone, trigger: 'blur' }],
        recipients: [{ required: true, message: '请输入收件人', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      // 刷新页面时数据丢失 返回列表重选
      const initData = this.$route.params;
      if (!initData.moneyAll) {
        this.$message({
          message: '已选数据丢失，请重新选择！',
          type: 'warning',
        });
        this.$router.back(-1);
      } else {
        this.contractAmount = initData.moneyAll;
        this.ids = initData.initFormData;
      }
    },
    submit(data) {
      data.contractAmount = this.contractAmount;
      data.ids = this.ids;
      this.$ajax({
        url: POST_CONTRACT_ADDCONTRACT,
        data: { ...data },
      }).then((res) => {
        if (!res.data.success) {
          return this.$message({
            message: '提交申请失败！',
            type: 'error',
          });
        }
        this.$message({
          message: '提交申请成功！',
          type: 'success',
        });
        this.$router.push({
          path: '/order/contract/indexContract',
        });
      });
    },
    doSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.ruleForm);
        } else {
          return false;
        }
      });
    },
    // 获取合同模板
    getContractModel() {
      // debugger;
      loadContractModel().then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message || '获取模板失败！',
          });
        }
        const urlArr = result.obj[0].values.HTMB_MBFJ.split('*');
        const urlName = urlArr[0]; // 下载文件的名字
        const url = `/jepaas/document/preview?fileKey=${urlArr[1]}`;
        const tempLink = document.createElement('a');
        tempLink.setAttribute('target', '_blank');
        tempLink.style.display = 'none';
        tempLink.href = url;
        tempLink.download = urlName;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .hover-download {
    cursor: pointer;
    color: #409EFF;
  }
</style>
<style lang="less">
.download_but{
  cursor: pointer;
  text-align: right;
  font-size:16px;
  font-weight:400;
  line-height:22px;
  a {
    color: #376BD0;
    text-decoration: none;
    &:hover {
      opacity: .7;
    }
  }
}
.contract-ruleForm {
  background-color: #fff;
  border-radius: 6px;
  padding: 40px 24px;
  margin-top: 20px;
  .el-form-item__label {
    font-size: 16px;
    color: #3f3f3f;
    padding-right: 40px;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-divider--horizontal {
    margin: 60px 0;
  }
  .submitBtn {
    text-align: center;
    .el-form-item__content {
      margin-left: 0 !important;
      .el-button {
        width: 500px;
        background: rgba(246, 71, 78, 1);
        border-radius: 8px;
        color: #fff;
        &:hover {
          background: rgba(246, 71, 78, 0.8);
        }
      }
    }
  }
  .el-input {
    width: 430px;
  }
  .el-select {
    .el-input {
      width: 240px;
    }
  }
  .sjr {
    width: 240px;
  }
}
</style>
